$padding-x: 8.72%;

.content,
.control {
  padding-left: $padding-x;
  padding-right: $padding-x;
  padding-top: 0;
}

.content {
  color: #FFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: 6.41%;
  padding-top: 15.37%;
  position: relative;
  z-index: 1;

  .app {
    align-items: center;
    display: flex;
    justify-content: center;

    .logo {
      $size: 111px;

      height: $size;
      margin-right: 29px;
      position: relative;
      width: $size;

      img {
        left: -2px;
        height: 166px;
        max-width: none;
        position: absolute;
        top: -2px;
      }
    }

    .texts {
      filter: drop-shadow(0px 3.471px 3.471px rgba(0, 0, 0, 0.25));
      flex-shrink: 0;
      text-align: left;

      .name {
        font-size: 19px;
        letter-spacing: 0.577px;
        line-height: 130%;
      }

      .gallery {
        background: linear-gradient(90deg, #E7B04E 41.59%, #F9E08C 64.1%, #F3CB6E 78.4%, #DDA03F 90.57%);
        background-clip: text;
        font-size: 31px;
        font-weight: bold;
        letter-spacing: 1.872px;  
        line-height: 120%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .form {
    color: #E9E3E4;

    .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 8px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    form {
      padding: 0;

      .input {
        $background: rgba(255, 255, 255, 0.1);
        $icon-width: 18px;
        $padding-x: 15px;

        margin-top: 15px;
        position: relative;

        input {
          background-color: $background;
          border: 1px solid rgba(255, 255, 255, 0.30);
          border-radius: 14px;
          color: #fff;
          font-size: 16px;
          line-height: 18px;
          outline: 0;
          padding: 13px $padding-x 13px $icon-width + $padding-x + 10px;
          width: 100%;
  
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        &:first-child {
          margin-top: 0;
        }

        &::before {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 100%;
          left: $padding-x;
          position: absolute;
          top: 0;
          width: $icon-width;
        }

        &.email::before {
          background-image: url('/images/auth/email.png');
        }

        &.password {
          $icon-width: 18px;

          button {
            background-image: url('/images/auth/close-eye.png');
            background-color: rgba(0, 0, 0, 0);
            border: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 100%;
            outline: 0;
            right: $padding-x;
            padding: 0;
            position: absolute;
            top: 0;
            width: $icon-width;
          }

          &::before {
            background-image: url('/images/auth/lock.png');
          }

          &.show button {
            background-image: url('/images/auth/open-eye.png');
          }
        }
      }

      .error {
        color: #FDA29B;
        font-size: 13px;
        margin-top: 7px;
        text-align: left;
        padding: 0 15px;
      }
    }

    .premium {
      font-size: 12px;
      line-height: normal;
      margin-top: 16px;
    }
  }
}

.control {
  flex-shrink: 0;
  padding-bottom: 40px;
  z-index: 1;

  button.action {
    background: linear-gradient(90deg, #F69D1C 0%, #FFB900 100%);
    border-radius: 14px;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    line-height: 51px;
    padding: 0;
    transition: opacity 300ms ease-in-out;
    width: 100%;

    &:disabled {
      opacity: 0.4;
    }
  }
}