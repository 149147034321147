
body {
  font-family: 'Product Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  /* display: flex; */
  /* justify-content: center; */
  /* align-content: center; */
  height: 100%;
  min-height:100vh;
  /* width: 100vw; */
  /* background: linear-gradient(318.96deg, rgba(0, 0, 0, 0.4) -1.9%, rgba(36, 36, 36, 0.4) 105%), #343434; */
  background: #A6A6A6;
  overflow-x: hidden;
}


form {
  /* min-width: 500px; */
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.wf-title {
  color: white;
  font-family: 'Product Sans', sans-serif !important;
  font-weight: 700;
  letter-spacing: 0,37px;
  font-size: 1.3rem!important;
  margin-left: 15vw;
}

.wf-title-howto {
  color: white;
  font-family: 'Product Sans', sans-serif !important;
  font-weight: 700;
  letter-spacing: 0,37px;
  font-size: 1.7rem!important;
}

#payment-message {
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.payment-header {
  color: #000000;
  font-family: 'Product Sans', sans-serif !important;
  text-align: center;
  font-weight: 500;
}

.payment-second-header {
  color: #000000;
  font-family: 'Product Sans', sans-serif !important;
  text-align: center;
  font-weight: 500;
}

#payment-element {
  margin-bottom: 24px;
}

#payment-form {
  padding-top: 5vw;
  padding-left:0;
  padding-right:0;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: 'Product Sans', sans-serif !important;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    /* width: 80vw; */
    min-width: initial;
  }
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.facebookLoginBtn {
  margin-top: 3px;
  background-color: rgb(18, 79, 178);
  color: rgb(255, 255, 255);
  height: 50px;
  width: 240px;
  border: none;
  text-align: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
  font-size: 16px;
  line-height: 48px;
  display: block;
  border-radius: 1px;
  transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s;
  font-family: 'Product Sans', sans-serif !important;
  cursor: pointer;
  user-select: none;
}

.wf-buttons {
  background: linear-gradient(88.33deg, #F7941D -7.64%, #F7D41D 145.94%);
  font-family: 'Product Sans', sans-serif !important;
  color: black;
  font-weight: 400;
  align-items: center;
  text-align: center; 
  line-height: 0px;
  border-width: 0;
}

.preview-btn {
  border-top-left-radius: 12px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 12px;
  margin-top: 5px;
  height: 27px;
  border-width: 0;
}

.buy-btn {
  border-radius: 15px;
  /* border-top-left-radius: 25px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 25px; */
  height: 51px;
  font-family: 'Product Sans', sans-serif;
  font-weight: 700;
  border-width: 0;
}

.wf-header {
  /* background: rgba(29, 29, 29, 0.94); */
  background: black;
  /* box-shadow: 0px 0.5px 0px rgba(255, 255, 255, 0.2); */
  /* backdrop-filter: blur(10px); */
}

.download-text {
  font-family: 'Product Sans', sans-serif !important;
  font-weight: 400;
  font-size: 0.9rem;
}

.preview-img-div {
  background: radial-gradient(50% 50% at 50% 50%, rgba(81, 69, 46, 0.7) 56.54%, rgba(32, 32, 32, 0) 100%);
}

.preview-image {
  position: absolute;
  width: 50%;
  top: 19%;
  left: 24%;
  border-radius: 17%;
}

.preview-video {
  position: fixed;
  width: 42%;
  top: 29.5vw;
  left: 28%;
  border-radius: 17%;
}

.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  font-family: 'Product Sans', sans-serif !important;
}

.video-container {
  position: relative;
  min-height: 33vw;
}

.video-container .overlay {
  position: absolute;
  top: 50%;
  left: 20%;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.video-container .overlay p {
  margin: 0;
  font-family: 'Product Sans', sans-serif !important;
}

.video-container .overlay p.overlay-date {
  font-size: 2vw;
  margin-left: 4vw;
  margin-bottom: -1vw;
  margin-top: 3vw;
}

.video-container-preview {
  position: relative;
}

.video-container-preview .overlay {
  position: fixed;
  top: 60vw;
  left: 8%;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 6vw;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.video-container-preview .overlay p {
  margin: 0;
  font-family: 'Product Sans', sans-serif !important;
}

.video-container-preview .overlay p.overlay-date {
  font-size: 3vw;
  margin-left: 4vw;
  margin-bottom: -2vw;
  margin-top: 5vw;
}

.offer-text {
  font-size: 4vw;
  font-weight: 700;
}

.offer-price {
  padding-top: 2vw;
  font-size: 80%;
  padding-bottom: 2vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.offer-price-checkout {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: white;
  font-family: 'Product Sans', sans-serif !important;

}

.old-price {
  margin-left: 1vw;
  margin-right: 1vw;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: line-through;
}

.old-price-checkout {
  margin-left: 1vw;
  margin-right: 1vw;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: line-through;
  background-color: #444444;
  border-radius: 3px;
}

.guarantee-part {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: white;
  font-family: 'Product Sans', sans-serif !important;
  font-size: 3vw;
  justify-content: space-evenly;
}

.stripe-text-block {
  border: 1px solid black;
  margin-left: 1vw;
  padding: 0.5vw;
  color: black;
}

.sale {
  background:linear-gradient(270deg, #C292FF -0.86%, #6D39FF 104.31%);
  font-size: 70%;
  height: 100%;
  width: 11vw;
  border-radius: 5px;
}


@media only screen and (max-width: 320px) {
  .video-container {
    min-height: 8vw;
  }

  .video-container .overlay {
    position: absolute;
    top: 0;
    left: -7%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .video-container .overlay p {
    margin-top: -1vw;
    margin-left: 8vw;
    margin-bottom: 0;
    margin-right: 0;
    font-family: 'Product Sans', sans-serif !important;
  }

  .video-container .overlay p.overlay-date {
    font-size: 2vw;
    margin-left: 15vw;
    margin-bottom: 0;
    margin-top: 16.5vw;
  }
}


@media only screen and (min-width: 600px) {
  .video-container {
    min-height: 8vw;
  }

  .video-container .overlay {
    position: absolute;
    top: 0;
    left: -7%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .video-container .overlay p {
    margin-top: -1vw;
    margin-left: 13.5vw;
    margin-bottom: 0;
    margin-right: 0;
    font-family: 'Product Sans', sans-serif !important;
  }

  .video-container .overlay p.overlay-date {
    font-size: 1.5vw;
    margin-left: 14.5vw;
    margin-bottom: 0;
    margin-top: 17.5vw;
  }
}

@media only screen and (min-width: 900px) {
  .video-container {
    min-height: 8vw;
  }

  .video-container .overlay {
    position: absolute;
    top: 0;
    left: -7%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .video-container .overlay p {
    margin-top: -0.3vw;
    margin-left: 7.5vw;
    margin-bottom: 0;
    margin-right: 0;
    font-family: 'Product Sans', sans-serif !important;
  }

  .video-container .overlay p.overlay-date {
    font-size: 1vw;
    margin-left: 8.5vw;
    margin-bottom: 0;
    margin-top: 9.5vw;
  }

  .payment-second-header{
    font-size: 3vw!important;
    line-height: 3.5vw!important;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .video-container {
    min-height: 8vw;
  }

  .video-container .overlay {
    position: absolute;
    top: 0;
    left: -7%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .video-container .overlay p {
    margin-top: -0.3vw;
    margin-left: 4.5vw;
    margin-bottom: 0;
    margin-right: 0;
    font-family: 'Product Sans', sans-serif !important;
  }

  .video-container .overlay p.overlay-date {
    font-size: 0.7vw;
    margin-left: 5vw;
    margin-bottom: 0;
    margin-top: 7.5vw;
  }

  .payment-content {
    max-width: 700px;
  }

  .payment-header {
    font-size: 2vw!important;
  }

  .payment-second-header{
    font-size: 1vw!important;
    line-height: 2vw!important;
    align-items: center;
    text-align: center;
  }
  
  .payment-offer {
    font-size: 1vw!important;
  }

  .guarantee-part span {
    font-size: 1.5vw!important;
  }

  .guarantee-part img {
    width: 1.5vw!important;
  }

  .stripe-text-block {
    font-size: 1.5vw!important;
    border: 1px solid black;
    margin-left: 1vw;
    padding: 0.5vw;
    color: black;
  }

  .payment-total {
    font-size: 2vw!important;
  }

  #payment-form {
    padding-top: 0!important;
  }
}


@media only screen and (min-width: 1535px) {
  .video-container {
    min-height: 8vw;
  }

  .video-container .overlay {
    position: absolute;
    top: 0;
    left: -7%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .video-container .overlay p {
    margin-top: 0;
    margin-left: 4vw;
    margin-bottom: 0;
    margin-right: 0;
    font-family: 'Product Sans', sans-serif !important;
  }

  .video-container .overlay p.overlay-date {
    font-size: 0.5vw;
    margin-left: 5vw;
    margin-bottom: 0;
    margin-top: 5.5vw;
  }
}


.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);

  }
}

.terms-links {
    padding-top:2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
}

.link-light {
  text-decoration: underline;
}

.bm-burger-button {
  position: absolute;
  width: 27px;
  height: 20px;
  left: 16px;
}
.close-button {
  position: relative;
  width: 27px;
  height: 20px;
  /* margin-left: 90%; */
  margin-top: 2vw;
  margin-bottom: 3vw;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  height: 0.4vw;
  opacity: 100;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0%;
  opacity: 1;
}

.bm-burger-cross {
  background: white;
  height: 0.4vw;
  opacity: 100;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 2vw;
  opacity: 1;
}

.bm-burger-cross-preview {
  background: white;
  height: 0.2vw;
  opacity: 100;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 2vw;
  opacity: 1;
  width: 1.25rem;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
  height: 0.4vw!important;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0%;
  opacity: 1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  width: 2px!important;
  height: 20px!important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: absolute;
  height: 50%;
  width: 100%!important;
}

/* General sidebar styles */
.bm-menu {
  /* background: linear-gradient(318.96deg, rgba(0, 0, 0, 0.4) -1.9%, rgba(36, 36, 36, 0.4) 105%), #343434; */
  background: black!important;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 100%!important;
  top: 15vw;
  position: absolute;
  /* height: 50%; */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: linear-gradient(318.96deg, rgba(0, 0, 0, 0.4) -1.9%, rgba(36, 36, 36, 0.4) 105%), #343434;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: linear-gradient(318.96deg, rgba(0, 0, 0, 0.4) -1.9%, rgba(36, 36, 36, 0.4) 105%), #343434;
  height: 25%;
}

.sale-header {
  font-weight: 900;
  color: black;
  background-color: white;
}
/* 
.sale-header h1 {
  animation: blinker 2s linear infinite;
} */

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.show-more-button {
  border-width: 0;
  width: 50%;
}

.guarantee-part-preview {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: white;
  font-family: 'Product Sans', sans-serif !important;
  font-size: 4vw;
}

.menu-item {
  color: white;
  text-decoration: none;
  font-size: 7vw;
  font-weight: 700;
  font-family: 'Product Sans', sans-serif !important;
  margin-top: 2vw;
}


.preview-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centered-content {
  text-align: center;
  flex-grow: 1;
  font-family: 'Product Sans', sans-serif !important;
}

.banner-offer {
  width: 100%;
  height: 6%;
  position: absolute;
  top: 0;
}

.loader-cards {
  border: 10px solid #ffffff; 
  border-top: 10px solid #000000; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  left: 35%;
  position: absolute;
  z-index: 999;
  top: 110vw;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.email-conf-modal {
  color: #020246;
  font-family: 'Product Sans', sans-serif !important;
}

.email-header {
  font-weight: 700;
  font-size: 5vw;
  margin-top: 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.email-subheader {
  margin-top: 3vw;
  font-weight: 500;
  font-size: 4.5vw;
}

.email-support-text {
  font-weight: 400;
  margin-top: 3.5vw;
  color: #00000080;
}


.email-input {
  position: relative;
  margin-top: 4vw;
}

.input-field {
  margin-top: 1vw;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.confirm-email {
  margin-top: 3vw;
  background: #32C770;
  color: white;
}


.welcomeBanner {
  background: white !important;
  border-radius: 20px;
  height: 17vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-left: 2.5%;
}

.first-welcome-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.close-banner-button {

  background: #000000;
  border-radius: 15px;
  width: 20vw;
  height: 7vw;

  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 4vw;
  line-height: 17px;
  display: flex;

  align-items: center;
  justify-content: center;

  color: #FFFFFF;
}

.welcome-banner-text {
  display: flex;
  flex-direction: column;
  margin-left: 5vw;
  margin-right: 5vw;
  padding-right: 5vw;
}

.bm-burger-cross-change-email {
  background: black;
  z-index: 9999;
  height: 0.2vw;
  opacity: 100;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 2vw;
  opacity: 2;
  width: 1.25rem;
}

.close-change-email {
  position: relative;
  right: 1vw;
  margin-left: 90%;
}