.wrapper {
  height: 100%;
  position: absolute;
  width: 100%;

  .root {
    background: linear-gradient(180deg, #130B2C 16.67%, #2A5CE2 47.92%, #2857D8 74.48%, #132A7E 100%);
    height: 100%;
    overflow: hidden;
    line-height: normal;
    margin: 0 auto;
    max-width: 428px;
    position: relative;
    text-align: center;
  
    .inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      width: 100%;
    
      &::after {
        background: linear-gradient(0deg, #060110 13.62%, rgba(6, 1, 16, 0.511765) 67.39%, rgba(6, 1, 16, 0) 100%);
        bottom: 0;
        content: '';
        display: block;
        height: 44%;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}